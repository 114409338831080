'use client'
import Head from "next/head";
import { useRouter } from "next/navigation";
import Link from "next/link";
import styles from './error.module.css'
import buttons from "components/_styled/buttons.module.css";

import LazyImage from "components/LazyImage";

const Error404 = () => {
  const router = useRouter();

  return (
    <div className={styles.Wrapper}>
      <Head>
        <title>Sorry</title>
      </Head>
      <div className={styles.Container}>
        <h1>404 Error</h1>
        <p>Sorry, either you are trying to view an unknown page or this page no longer exists on Completely Retail</p>
        <section className={styles.Links}>
          <a className={buttons.primaryButton}
            onClick={(e) => {
              e.preventDefault();
              router.back();
            }}
          >
            Go Back
          </a>
          <Link href="/search" passHref legacyBehavior prefetch={false}>
            <a className={buttons.primaryButton}>Try Another Search</a>
          </Link>
          <Link href="/search" href="/contact" passHref legacyBehavior prefetch={false}>
          <a className={buttons.primaryButton}>
              Contact Us
            </a>
          </Link>
        </section>
      </div>
      <LazyImage
        alt="Sorry, we have run into a problem on this page"
        src={`${process.env.NEXT_PUBLIC_APP_CDN_URL}error/404.png`}
        sources={[
          {
            srcSet: `${process.env.NEXT_PUBLIC_APP_CDN_URL}error/404.png`,
            type: "image/webp",
          },
          {
            srcSet: `${process.env.NEXT_PUBLIC_APP_CDN_URL}error/404.png`,
            type: "image/png",
          },
        ]}
        layout="fill"
        objectFit="cover"
      />
    </div>
  );
};

export default Error404;
